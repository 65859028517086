import { Routes, Route } from "react-router-dom";

import {
  Login,
  RegisterForm,
  RegisterMessage,
  ForgotPass,
  ChangePass,
  Vacation,
  VacationRequest,
  RequestApproval,
  PersonalVacationRequests,
} from "./pages";
import { ErrorAlerts } from "./layout";
import PrivateRoutes from "./util/PrivateRoutes";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/register/message" element={<RegisterMessage />} />
        <Route path="/forgot-pass" element={<ForgotPass />} />
        <Route path="/forgot-pass/change-pass" element={<ChangePass />} />
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Vacation />} />
          <Route path="/cerere-concediu" element={<VacationRequest />} />
          <Route path="/aprobare-cereri" element={<RequestApproval />} />
          <Route path="/cererile-tale" element={<PersonalVacationRequests />} />
        </Route>
      </Routes>

      <ErrorAlerts />
    </div>
  );
}

export default App;
