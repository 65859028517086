export const roRO = {
    // Calendar navigation
    previousMonth: 'Luna anterioară',
    nextMonth: 'Luna următoare',

    // View navigation
    openPreviousView: 'Deschideți vizualizarea anterioară',
    openNextView: 'Deschideți următoarea vizualizare',
    calendarViewSwitchingButtonAriaLabel: (view) =>
        view === 'year'
            ? 'vizualizarea anuală este deschisă, schimbați la vizualizarea calendarului'
            : 'vizualizarea calendarului este deschisă, schimbați la vizualizarea anuală',

    // DateRange placeholders
    start: 'Început',
    end: 'Sfârșit',

    // Action bar
    cancelButtonLabel: 'Anulare',
    clearButtonLabel: 'Șterge',
    okButtonLabel: 'OK',
    todayButtonLabel: 'Astăzi',

    // Toolbar titles
    datePickerToolbarTitle: 'Selectați data',
    dateTimePickerToolbarTitle: 'Selectați data și ora',
    timePickerToolbarTitle: 'Selectați ora',
    dateRangePickerToolbarTitle: 'Selectați intervalul de date',

    // Clock labels
    clockLabelText: (view, time, adapter) =>
        `Selectați ${view}. ${time === null ? 'Nu ați selectat ora' : `Ora selectată este ${adapter.format(time, 'fullTime')}`
        }`,
    hoursClockNumberText: (hours) => `${hours} ore`,
    minutesClockNumberText: (minutes) => `${minutes} minute`,
    secondsClockNumberText: (seconds) => `${seconds} secunde`,

    // Calendar labels
    calendarWeekNumberHeaderLabel: 'Număr săptămână',
    calendarWeekNumberHeaderText: '#',
    calendarWeekNumberAriaLabelText: (weekNumber) => `Săptămâna ${weekNumber}`,
    calendarWeekNumberText: (weekNumber) => `${weekNumber}`,

    // Open picker labels
    openDatePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Alegeți data, data selectată este ${utils.format(value, 'fullDate')}`
            : 'Alegeți data',
    openTimePickerDialogue: (value, utils) =>
        value !== null && utils.isValid(value)
            ? `Alegeți ora, ora selectată este ${utils.format(value, 'fullTime')}`
            : 'Alegeți ora',

    // Table labels
    timeTableLabel: 'Selectați ora',
    dateTableLabel: 'Selectați data',

    // Field section placeholders
    fieldYearPlaceholder: (params) => 'A'.repeat(params.digitAmount),
    fieldMonthPlaceholder: (params) => (params.contentType === 'letter' ? 'LLLL' : 'LL'),
    fieldDayPlaceholder: () => 'ZZ',
    fieldWeekDayPlaceholder: (params) => (params.contentType === 'letter' ? 'EEEE' : 'EE'),
    fieldHoursPlaceholder: () => 'oo',
    fieldMinutesPlaceholder: () => 'mm',
    fieldSecondsPlaceholder: () => 'ss',
    fieldMeridiemPlaceholder: () => 'aa',
}
