import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DownloadIcon from "@mui/icons-material/Download";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

import { downloadLeaveRequestPDF } from "../../actions/leaveActions";
import "./style.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const LeaveRequestTable = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading);
  const currentRows = useSelector((state) => state.others_vacation_requests);

  const leaveType = {
    vacation: "Odihnă",
    event_fam: "Eveniment familial",
    no_pay: "Fără plată",
    child: "Îngrijire copil",
  };

  const statusMapping = {
    awaiting_sub: "Înlocuitor",
    awaiting_sup: "Șef departament",
    awaiting_hr: "Resurse umane",
  };

  function handleAcceptRequest(request_id, substitute, request_status) {
    dispatch({
      type: "SET_VACATION_CONFIRMATION",
      payload: {
        request_id: request_id,
        is_substitute: substitute,
        request_status: request_status,
        open_approve_overlay: true,
      },
    });
  }

  function handleDenyRequest(request_id, substitute) {
    dispatch({
      type: "SET_VACATION_CONFIRMATION",
      payload: {
        request_id: request_id,
        is_substitute: substitute,
        open_deny_overlay: true,
      },
    });
  }

  function handleDownloadPDF(id) {
    dispatch(downloadLeaveRequestPDF(id));
  }

  const tableRow = (row, substitute) => {
    return (
      <StyledTableRow
        key={row.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
        style={substitute ? { backgroundColor: "#ffedd5" } : {}}
      >
        {props.currentTable === "to_approve" && (
          <>
            <StyledTableCell align="center">
              {row.can_approve && (
                <IconButton
                  aria-label="close"
                  onClick={() =>
                    handleAcceptRequest(row.id, substitute, row.status)
                  }
                >
                  <CheckCircleIcon color="success" />
                </IconButton>
              )}
              <IconButton
                aria-label="close"
                onClick={() => handleDenyRequest(row.id, substitute)}
              >
                <CancelIcon color="error" />
              </IconButton>
            </StyledTableCell>
            <StyledTableCell align="center">
              {statusMapping[row.status]}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell component="th" scope="row">
          {row.surname + " " + row.name}
        </StyledTableCell>
        <StyledTableCell align="center">{row.role}</StyledTableCell>
        <StyledTableCell align="center">
          {dayjs.unix(row.start_date).format("DD.MM.YYYY")}
        </StyledTableCell>
        <StyledTableCell align="center">
          {dayjs.unix(row.end_date).format("DD.MM.YYYY")}
        </StyledTableCell>
        <StyledTableCell align="center">{row.work_days}</StyledTableCell>
        <StyledTableCell align="center">
          {row.leave_type === "other"
            ? row.other_details
            : leaveType[row.leave_type]}
        </StyledTableCell>
        {props.currentTable === "denied" && (
          <StyledTableCell align="center">
            {substitute ? "-" : row.explanation}
          </StyledTableCell>
        )}
        {props.currentTable === "approved" && (
          <StyledTableCell align="center">
            <IconButton
              aria-label="download"
              disabled={row.status === "approved" ? false : true}
              onClick={() => handleDownloadPDF(row.id)}
            >
              <DownloadIcon />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <>
      <TableContainer
        id="leave-request-table"
        component={Paper}
        style={{ maxHeight: "70vh" }}
      >
        <Table stickyHeader aria-label="leave request table">
          <TableHead>
            <TableRow>
              {props.currentTable === "to_approve" && (
                <>
                  <StyledTableCell align="center"></StyledTableCell>
                  <StyledTableCell align="center">
                    Stadiul aprobării
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell>Nume solicitant</StyledTableCell>
              <StyledTableCell align="center">Funcție</StyledTableCell>
              <StyledTableCell align="center">Data începere</StyledTableCell>
              <StyledTableCell align="center">Data sfarșit</StyledTableCell>
              <StyledTableCell align="center">Zile lucrătoare</StyledTableCell>
              <StyledTableCell align="center">Tip concediu</StyledTableCell>
              {props.currentTable === "denied" && (
                <StyledTableCell align="center">
                  Motivul respingerii
                </StyledTableCell>
              )}
              {props.currentTable === "approved" && (
                <StyledTableCell align="center">PDF</StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {currentRows.to_substitute[props.currentTable].map((row) =>
              tableRow(row, true)
            )}
            {currentRows.subordinates[props.currentTable].map((row) =>
              tableRow(row, false)
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(!currentRows.to_substitute[props.currentTable].length &&
        !currentRows.subordinates[props.currentTable].length) && (
        <p style={{ fontStyle: "italic" }}> -- tabel gol -- </p>
      )}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default LeaveRequestTable;
