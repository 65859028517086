import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';

const StrengthMeter = (props) => {
    const requirements = [
        'Parola trebuie să conțină o literă mică.',
        'Parola trebuie să conțină o literă mare.',
        'Parola trebuie să conțină o cifră.',
        'Parola trebuie să conțină un caracter special.',
        'Minim 8 caractere permise.'
    ];

    const [message, setMessage] = useState('');

    useEffect(() => {
        let completedRequirements = 0;

        if (props.password.match(/[a-z]/)) {
            completedRequirements++;
            setMessage(requirements[1]);
        } else {
            setMessage(requirements[0]);
        }
        
        if (props.password.match(/[A-Z]/) && completedRequirements === 1) {
            completedRequirements++;
            setMessage(requirements[2]);
        }
        
        if (props.password.match(/[0-9]/) && completedRequirements === 2) {
            completedRequirements++;
            setMessage(requirements[3]);
        }
        
        if (props.password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) && completedRequirements === 3) {
            completedRequirements++;
            setMessage(requirements[4]);
        }
        
        if (props.password.length >= 8 && completedRequirements === 4) {
            completedRequirements++;
            setMessage('');
        }

        props.setProgress((completedRequirements / requirements.length) * 100);

        if (props.password.length >= 64 && completedRequirements === 5) {
            setMessage('Maxim 64 caractere permise');
            props.setProgress(0)
        } else if (completedRequirements === 5) {
            setMessage('');
        }

    }, [props.password]);

    return (
        <>
            <div style={{ color: 'red', marginBottom: '0.7rem' }}>
                {message}
            </div>
            <LinearProgress variant="determinate" color="secondary" value={props.progress} sx={{ mb: '0.7rem' }}/>
        </>
    );
};

export default StrengthMeter;