import axios from "axios";

export const loginUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        data
      );
      if (response.status === 200) {
        const token = response.data.token;

        sessionStorage.setItem("token", token);

        window.location.replace("/");
      }
      dispatch({ type: "STOP_LOADING" });
    } catch (err) {
      let error_message =
        "Nu am găsit un cont pentru datele introduse. Vă rugăm verificați informațiile și încercați din nou!";
      if (err.response.data === "Unverified email") {
        error_message =
          "Verificați-vă e-mailul pentru link-ul de activare. Dacă link-ul a expirat, completați un nou formular de înregistrare.";
      }
      dispatch({
        type: "ERROR",
        payload: error_message,
      });
    }
  };
};

export const registerUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users`,
        data
      );
      dispatch({ type: "STOP_LOADING" });

      if (response.status === 201) {
        window.location.replace("/register/message");
      }
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload:
          "Ceva nu a funcționat correct! Vă rugăm verificați informațiile introduse și încercați din nou!",
      });
    }
  };
};

export const forgotUser = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/users/forgot-pass`,
        data
      );
      dispatch({ type: "STOP_LOADING" });
      window.location.replace("/login");
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload: "Ceva nu a funcționat correct! Vă rugăm să încercați din nou!",
      });
    }
  };
};

export const changePassUser = (data, token) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL}/users/reset-pass`,
        data,
        {
          headers: {
            authorization: token,
          },
        }
      );
      dispatch({ type: "STOP_LOADING" });
      window.location.replace("/login");
    } catch (err) {
      let error_message;
      if (err.response.status === 401) {
        error_message = "Link-ul este expirat sau invalid!";
      } else {
        error_message =
          "Ceva nu a funcționat correct! Vă rugăm să încercați din nou!";
      }
      dispatch({
        type: "ERROR",
        payload: error_message,
      });
    }
  };
};

export const checkToken = () => {
  return async (dispatch) => {
    try {
      if (sessionStorage.getItem("token")) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}`, {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        });
        if (response.status === 200) {
          dispatch(getHomePageData());
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload: "Sesiunea a expirat.",
      });
      return false
    }
  };
};

const getHomePageData = () => {
  return async (dispatch) => {
    try {
      const userData = await axios.get(
        `${process.env.REACT_APP_API_URL}/users/home-page`,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      
      dispatch({
        type: "SET_DAYS&DATES",
        payload: userData.data,
      });
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload:
          "Ceva nu a funcționat correct! Vă rugăm să reîncărcați pagina!",
      });
    }
  };
};
