import { useEffect, useState, useRef } from 'react';
import { useSelector } from "react-redux";

const CircularBar = () => {
  const total = useSelector((state) => state.days_total);
  const left = useSelector((state) => state.days_left);

  const canvasRef = useRef(null);
  const [percentage, setPercentage] = useState(0)
  const [hoverText, setHoverText] = useState(false)

  useEffect(() => {
    setPercentage(left / total * 100)
  }, [left, total])

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    const radius = 80;
    const startAngle = -0.5 * Math.PI;
    const endAngle = (2 * percentage / 100 - 0.5) * Math.PI;

    context.clearRect(0, 0, canvas.width, canvas.height);

    context.beginPath();
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
    context.strokeStyle = '#e6e6e6';
    context.lineWidth = 10;
    context.stroke();

    context.beginPath();
    context.arc(centerX, centerY, radius, startAngle, endAngle);
    context.strokeStyle = '#6366f1';
    context.stroke();

    context.textAlign = 'center';
    context.textBaseline = 'middle';

    context.font = 'bold 0.9rem Montserrat, sans-serif';
    context.fillStyle = '#808080';
    context.fillText(hoverText ? '' : 'ZILE', centerX, centerY - 40);
    context.fillText(hoverText ? 'TOTAL' : 'RĂMASE', centerX, centerY - 20);

    context.font = 'bold 1.8rem Montserrat, sans-serif';
    context.fillStyle = '#6366f1';
    context.fillText(hoverText ? `${total}` : `${left}`, centerX, centerY + 15);
  }, [percentage, left, total, hoverText]);

  return (
    <canvas
      ref={canvasRef}
      width={200}
      height={200}
      onMouseEnter={() => setHoverText(true)}
      onMouseLeave={() => setHoverText(false)}
    />
  );
};

export default CircularBar;