import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

import {
  LeaveRequestTable,
  ApproveRequest,
  RejectRequest,
  LinearCalendar,
} from "../../components";
import {
  getRequestsOfOthers,
  approveRequest,
  denyRequest,
} from "../../actions/leaveActions";
import "./style.css";

const RequestApproval = () => {
  const dispatch = useDispatch();

  //for button group
  const [currentTable, setCurrentTable] = useState("to_approve");

  const employees = useSelector((state) => state.subordinates_vacation_requests);
  const leaveRequests = useSelector((state) => state.others_vacation_requests);
  const [refreshData, setRefreshData] = useState(true);
  useEffect(() => {
    if (refreshData) {
      dispatch(getRequestsOfOthers())
      setRefreshData((prev) => !prev);
    }
  }, [refreshData]);

  async function handleConfirmApproval(overlayData) {
    dispatch(approveRequest(overlayData)).then((response) => {
      if (response === "ok") setRefreshData(true);
    });
  }

  async function handleConfirmDenial(overlayData) {
    dispatch(denyRequest(overlayData)).then((response) => {
      if (response === "ok") setRefreshData(true);
    });
  }

  return (
    <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingTop: "2rem" }}>
      <ToggleButtonGroup
        className="request-table-switch"
        color="secondary"
        value={currentTable}
        exclusive
        onChange={(event, newValue) => {
          if (newValue) {
            setCurrentTable(newValue);
          }
        }}
        aria-label="table switch"
        sx={{ pb: "1.2rem" }}
      >
        <ToggleButton value="to_approve">De aprobat</ToggleButton>
        <ToggleButton value="approved">Aprobate</ToggleButton>
        <ToggleButton value="denied">Respinse</ToggleButton>
      </ToggleButtonGroup>

      <LeaveRequestTable
        currentTable={currentTable}
      />

      <LinearCalendar
        employees={employees}
        leaveRequests={leaveRequests.subordinates}
      />

      <ApproveRequest
        handleConfirmationSubmission={handleConfirmApproval}
      />

      <RejectRequest
        handleConfirmationSubmission={handleConfirmDenial}
      />
    </div>
  );
};

export default RequestApproval;
