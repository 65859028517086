const initState = {
  days_total: 0,
  days_left: 0,
  vacation_requests: [],
  email_supervisor: "",
  loading: false,
  error: "",
  others_vacation_requests: {
    to_substitute: {
      to_approve: [],
      approved: [],
      denied: [],
    },
    subordinates: {
      to_approve: [],
      approved: [],
      denied: [],
    },
  },
  subordinates_vacation_requests: [],
  vacation_confirmation: {
    request_id: null,
    is_substitute: false,
    request_status: null,
    open_approve_overlay: false,
    open_deny_overlay: false,
  },
};

const stateReducer = (state = initState, action) => {
  switch (action.type) {
    case "SET_DAYS&DATES":
      return {
        ...state,
        days_total: action.payload.days_total,
        days_left: action.payload.days_left,
        vacation_requests: action.payload.leave_requests,
        email_supervisor: action.payload.email_supervisor,
      };
    case "SET_VACATION_REQUESTS_OF_OTHERS":
      return {
        ...state,
        others_vacation_requests: action.payload.table_separated,
        subordinates_vacation_requests: action.payload.subordinates_original,
      };
    case "SET_VACATION_CONFIRMATION":
      return {
        ...state,
        vacation_confirmation: {
          ...initState.vacation_confirmation,
          ...action.payload,
        },
      };
    case "LOADING":
      return { ...state, loading: true };
    case "STOP_LOADING":
      return { ...state, loading: false };
    case "ERROR":
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default stateReducer;
