import dayjs from "dayjs";

export const generateDate = (
  month = dayjs().month(),
  year = dayjs().year()
) => {
  const firstDateofMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateofMonth = dayjs().year(year).month(month).endOf("month");

  const allDatesOfCalendar = [];

  //create previous month dates (week starts from Sun so to get Mon let i = 1)
  for (let i = 0; i < (firstDateofMonth.day() + 6) % 7; i++) {
    allDatesOfCalendar.push({
      date: null,
    });
  }

  //generate current month dates
  for (let i = firstDateofMonth.date(); i <= lastDateofMonth.date(); i++) {
    allDatesOfCalendar.push({
      date: firstDateofMonth.date(i),
      today:
        firstDateofMonth.date(i).toDate().toDateString() ===
        dayjs().toDate().toDateString(),
    });
  }

  //create next month dates
  const remaining = 42 - allDatesOfCalendar.length;
  for (
    let i = lastDateofMonth.date() + 1;
    i <= lastDateofMonth.date() + remaining;
    i++
  ) {
    allDatesOfCalendar.push({
      date: null,
    });
  }

  return allDatesOfCalendar;
};

export const months = [
  "Ianuarie",
  "Februarie",
  "Martie",
  "Aprilie",
  "Mai",
  "Iunie",
  "Iulie",
  "August",
  "Septembrie",
  "Octombrie",
  "Noiembrie",
  "Decembrie",
];

const holidays = [
  1674511200, 1681419600, 1685566800, 1692046800, 1701295200, 1701381600,
  1703541600, 1704146400, 1706047200, 1714683600, 1714510800, 1717189200,
  1723669200, 1732917600, 1735077600, 1735164000, 1735682400, 1735768800,
  1737669600, 1744923600, 1746046800, 1755205200, 1766613600, 1766700000,
  1767218400, 1767304800, 1769205600, 1775768400, 1777582800, 1786741200,
  1796076000, 1798149600, 1798236000, 1798754400, 1798840800, 1809032400,
  1809118800, 1811797200, 1827525600, 1827612000, 1829685600, 1830290400,
  1839272400, 1843419600, 1849899600, 1859148000, 1859234400, 1861394400,
];

export const calculateWorkdayDifference = (startDate, endDate) => {
  const start = startDate.startOf("day");
  const end = endDate.startOf("day");

  const totalDays = end.diff(start, "day") + 1;
  let workdays = 0;

  for (let i = 0; i < totalDays; i++) {
    const currentDate = start.add(i, "day");
    if (
      currentDate.day() !== 0 &&
      currentDate.day() !== 6 &&
      !holidays.includes(currentDate.unix())
    ) {
      workdays++;
    }
  }

  return workdays;
};
