import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";

import { downloadLeaveRequestPDF } from "../../actions/leaveActions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const PersonalVacationRequests = (props) => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);
  const personalRequests = useSelector((state) => state.vacation_requests);
  const [currentTable, setCurrentTable] = useState("to_approve");

  const defaultRows = {
    to_approve: [],
    approved: [],
    denied: [],
  };
  const [currentRows, setCurrentRows] = useState(defaultRows);
  useEffect(() => {
    setCurrentRows(defaultRows);
    personalRequests.forEach((request) => {
      if (request.status.includes("awaiting_")) {
        setCurrentRows((prevState) => ({
          ...prevState,
          to_approve: [...prevState.to_approve, request],
        }));
      } else if (request.status === "approved") {
        setCurrentRows((prevState) => ({
          ...prevState,
          approved: [...prevState.approved, request],
        }));
      } else if (request.status === "denied") {
        setCurrentRows((prevState) => ({
          ...prevState,
          denied: [...prevState.denied, request],
        }));
      }
    });
  }, [personalRequests]);

  const leaveType = {
    vacation: "Odihnă",
    event_fam: "Eveniment familial",
    no_pay: "Fără plată",
    child: "Îngrijire copil",
  };

  const statusMapping = {
    awaiting_sub: "Înlocuitor",
    awaiting_sup: "Șef departament",
    awaiting_hr: "Resurse umane",
  };

  function handleDownloadPDF(id) {
    dispatch(downloadLeaveRequestPDF(id))
  }

  const tableRow = (row, index) => {
    return (
      <StyledTableRow
        key={index}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        {currentTable === "to_approve" && (
          <>
            <StyledTableCell align="center">
              {statusMapping[row.status]}
            </StyledTableCell>
          </>
        )}
        <StyledTableCell align="center">
          {dayjs.unix(row.start_date).format("DD.MM.YYYY")}
        </StyledTableCell>
        <StyledTableCell align="center">
          {dayjs.unix(row.end_date).format("DD.MM.YYYY")}
        </StyledTableCell>
        <StyledTableCell align="center">{row.work_days}</StyledTableCell>
        <StyledTableCell align="center">
          {row.leave_type === "other"
            ? row.other_details
            : leaveType[row.leave_type]}
        </StyledTableCell>
        {currentTable === "approved" && (
          <StyledTableCell align="center">
            <IconButton
              aria-label="download"
              onClick={() => handleDownloadPDF(row.id)}
            >
              <DownloadIcon />
            </IconButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  };

  return (
    <div style={{ minHeight: "80vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingTop: "2rem" }}>
      <ToggleButtonGroup
        className="request-table-switch"
        color="secondary"
        value={currentTable}
        exclusive
        onChange={(event, newValue) => {
          if (newValue) {
            setCurrentTable(newValue);
          }
        }}
        aria-label="table switch"
        sx={{ pb: "1.2rem" }}
      >
        <ToggleButton value="to_approve">De aprobat</ToggleButton>
        <ToggleButton value="approved">Aprobate</ToggleButton>
        <ToggleButton value="denied">Respinse</ToggleButton>
      </ToggleButtonGroup>

      <TableContainer
        id="leave-request-table"
        component={Paper}
        style={{ maxHeight: "70vh" }}
      >
        <Table stickyHeader aria-label="leave request table">
          <TableHead>
            <TableRow>
              {currentTable === "to_approve" && (
                <>
                  <StyledTableCell align="center">
                    Stadiul aprobării
                  </StyledTableCell>
                </>
              )}
              <StyledTableCell align="center">Data începere</StyledTableCell>
              <StyledTableCell align="center">Data sfarșit</StyledTableCell>
              <StyledTableCell align="center">Zile lucrătoare</StyledTableCell>
              <StyledTableCell align="center">Tip concediu</StyledTableCell>
              {currentTable === "approved" && (
                <StyledTableCell align="center">PDF</StyledTableCell>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {currentRows[currentTable].map((row, index) =>
              tableRow(row, index)
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {!currentRows[currentTable].length &&
      <p style={{ fontStyle: "italic" }}> -- tabel gol -- </p>
      }
      
      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default PersonalVacationRequests;
