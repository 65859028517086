import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, MenuItem, FormControl, Select } from "@mui/material";
import dayjs from "dayjs";

import "./style.css";

const LeaveHistory = () => {
  const requests = useSelector((state) => state.vacation_requests);

  const [year, setYear] = useState(dayjs().year());

  const [vacationDays, setVacationDays] = useState(0);
  const [eventFamDays, setEventFamDays] = useState(0);
  const [childDays, setChildDays] = useState(0);
  const [noPayDays, setNoPayDays] = useState(0);
  const [otherDays, setOtherDays] = useState(0);

  useEffect(() => {
    setVacationDays(0);
    setEventFamDays(0);
    setChildDays(0);
    setNoPayDays(0);
    setOtherDays(0);

    if (requests.length) {
      for (const request of requests) {
        const startDateYear = dayjs.unix(request.start_date).year();
        if (startDateYear === year && request.status !== "denied") {
          switch (request.leave_type) {
            case "vacation":
              setVacationDays((prevState) => prevState + 1);
              break;
            case "event_fam":
              setEventFamDays((prevState) => prevState + 1);
              break;
            case "child":
              setChildDays((prevState) => prevState + 1);
              break;
            case "no_pay":
              setNoPayDays((prevState) => prevState + 1);
              break;
            case "other":
              setOtherDays((prevState) => prevState + 1);
              break;
            default:
              break;
          }
        }
      }
    }
  }, [requests, year]);

  return (
    <div id="leave-history">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h3>Istoric</h3>

        <Box sx={{ minWidth: 100, fontSize: "0.8rem" }}>
          <FormControl variant="standard">
            <Select
              id="year"
              value={year}
              onChange={(e) => setYear(e.target.value)}
              style={{ fontSize: "0.9rem" }}
            >
              <MenuItem value={dayjs().subtract(2, "year").year()}>
                {dayjs().subtract(2, "year").year()}
              </MenuItem>
              <MenuItem value={dayjs().subtract(1, "year").year()}>
                {dayjs().subtract(1, "year").year()}
              </MenuItem>
              <MenuItem value={dayjs().year()}>{dayjs().year()}</MenuItem>
              <MenuItem value={dayjs().add(1, "year").year()}>
                {dayjs().add(1, "year").year()}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </div>

      <div className="leave-history-table">
        <div className="leave-history-table-row">
          <div className="leave-history-table-column-1">
            • Concediu de odihnă
          </div>
          <div className="leave-history-table-column-2">
            {vacationDays ? vacationDays : "-"}
          </div>
        </div>
        <div className="leave-history-table-row">
          <div className="leave-history-table-column-1">
            • Concediu pentru evenimente familiale
          </div>
          <div className="leave-history-table-column-2">
            {eventFamDays ? eventFamDays : "-"}
          </div>
        </div>
        <div className="leave-history-table-row">
          <div className="leave-history-table-column-1">
            • Concediu creștere și îngrijire copil
          </div>
          <div className="leave-history-table-column-2">
            {childDays ? childDays : "-"}
          </div>
        </div>
        <div className="leave-history-table-row">
          <div className="leave-history-table-column-1">
            • Concediu fără plată
          </div>
          <div className="leave-history-table-column-2">
            {noPayDays ? noPayDays : "-"}
          </div>
        </div>
        <div className="leave-history-table-row">
          <div className="leave-history-table-column-1">
            • Alt tip de concediu
          </div>
          <div className="leave-history-table-column-2">
            {otherDays ? otherDays : "-"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveHistory;
