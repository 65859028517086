import { useState } from "react";
import { Paper, TextField, Zoom } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import { forgotUser } from "../../../actions/userActions";

function ForgotPass() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const [emailError, setEmailError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    if (!e.target.forgot_email.value) {
      setEmailError(true);
    } else {
      dispatch(forgotUser({ email: e.target.forgot_email.value }));
    }
  }

  return (
    <div className="auth-body">
      <Zoom in={true}>
        <Paper elevation={12} id="auth-card">
          <form onSubmit={handleSubmit}>
            <h2 style={{ color: "#6b7280" }}>Recuperare Parolă</h2>
            <p style={{ color: "grey" }}>
              După trimiterea cererii veți primi un email cu instrucțiunile
              pentru recuperarea parolei
            </p>
            <TextField
              color="secondary"
              id="forgot_email"
              label="EMAIL"
              sx={{ width: "100%", my: "0.7rem" }}
              onChange={() => setEmailError(null)}
              helperText={emailError ? "Completați acest spațiu" : ""}
              error={emailError ? true : false}
            />

            <LoadingButton
              id="auth-btn-mobile"
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: "fit-content", mt: "0.7rem" }}
              loading={loading}
              loadingPosition="center"
            >
              Trimite
            </LoadingButton>

            <hr width="100%" size="2" align="center" />

            <div id="auth-btns">
              <div></div>

              <LoadingButton
                id="auth-btns-2"
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: "fit-content" }}
                loading={loading}
                loadingPosition="center"
              >
                Trimite
              </LoadingButton>

              <a id="auth-btns-3" href="/login">
                Înapoi
              </a>
            </div>
          </form>
        </Paper>
      </Zoom>
    </div>
  );
}

export default ForgotPass;
