import { useState } from "react";
import {
  Paper,
  Zoom,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";

import { StrengthMeter } from "../../../components";
import { changePassUser } from "../../../actions/userActions";

function ForgotPass() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [passwordProgress, setPasswordProgress] = useState(0);

  function passErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  function confirmPassErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "Parola nu corespunde";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  const [passError, setPassError] = useState(null);
  const [confirmPassError, setConfirmPassError] = useState(null);

  function validateFormItems(e) {
    const { new_password, new_password_conf } = e.target;

    const errors = {
      password: !new_password.value
        ? "empty"
        : passwordProgress !== 100
        ? "invalid"
        : null,
      password_conf: !new_password_conf.value
        ? "empty"
        : new_password_conf.value !== new_password.value
        ? "invalid"
        : null,
    };

    setPassError(errors.password);
    setConfirmPassError(errors.password_conf);

    return Object.values(errors).every((error) => !error);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    const validItems = validateFormItems(e);

    if (validItems) {
      const passData = { password: e.target.new_password.value };
      const token = "Bearer " + window.location.search.split("?")[1];

      dispatch(changePassUser(passData, token));
    }
  }

  return (
    <div className="auth-body">
      <Zoom in={true}>
        <Paper elevation={12} id="auth-card">
          <form onSubmit={handleSubmit}>
            <h2 style={{ color: "#6b7280" }}>Schimbare Parolă</h2>

            <FormControl
              error={passError ? true : false}
              variant="outlined"
              sx={{ width: "100%", my: "0.7rem" }}
            >
              <InputLabel color="secondary" htmlFor="new_password">
                PAROLĂ NOUĂ
              </InputLabel>
              <OutlinedInput
                color="secondary"
                id="new_password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPassError(null);
                }}
                label="PAROLĂ NOUĂ"
                autoComplete="new-password"
              />
              <FormHelperText error id="accountId-error">
                {passErrorMessages(passError)}
              </FormHelperText>
            </FormControl>
            {!!password && (
              <StrengthMeter
                password={password}
                progress={passwordProgress}
                setProgress={setPasswordProgress}
              />
            )}

            <FormControl
              error={confirmPassError ? true : false}
              variant="outlined"
              sx={{ width: "100%", my: "0.7rem" }}
            >
              <InputLabel color="secondary" htmlFor="new_password_conf">
                CONFIRMĂ PAROLA
              </InputLabel>
              <OutlinedInput
                color="secondary"
                id="new_password_conf"
                type={showPasswordConf ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordConf((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPasswordConf ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={() => setConfirmPassError(null)}
                label="CONFIRMĂ PAROLA"
                autoComplete="new-password"
              />
              <FormHelperText error id="accountId-error">
                {confirmPassErrorMessages(confirmPassError)}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              id="auth-btn-mobile"
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: "fit-content", mt: "0.7rem" }}
              loading={loading}
              loadingPosition="center"
            >
              Trimite
            </LoadingButton>

            <hr width="100%" size="2" align="center" />

            <div id="auth-btns">
              <div></div>

              <LoadingButton
                id="auth-btns-2"
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: "fit-content" }}
                loading={loading}
                loadingPosition="center"
              >
                Trimite
              </LoadingButton>

              <a id="auth-btns-3" href="/login">
                Autentificare
              </a>
            </div>
          </form>
        </Paper>
      </Zoom>
    </div>
  );
}

export default ForgotPass;
