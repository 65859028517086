import { forwardRef, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import SignatureCanvas from "react-signature-canvas";
import {
  IconButton,
  Snackbar,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  useMediaQuery,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

import "./style.css";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ApproveRequest = (props) => {
  const dispatch = useDispatch();
  const sigCanvasRef = useRef(null);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loading = useSelector((state) => state.loading);
  const vacationConfirmation = useSelector(
    (state) => state.vacation_confirmation
  );
  function handleCloseOverlay() {
    dispatch({
      type: "SET_VACATION_CONFIRMATION",
    });
  }

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const [checked, setChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  const handleClearSignature = () => {
    sigCanvasRef.current.clear();
  };

  function handleSubmitForm(e) {
    e.preventDefault();

    let noErrors = true;
    if (
      vacationConfirmation.request_status !== "awaiting_hr" &&
      sigCanvasRef.current.isEmpty()
    ) {
      noErrors = false;
      handleOpenSnackbar();
    } else if (
      vacationConfirmation.request_status === "awaiting_hr" &&
      !checked
    ) {
      noErrors = false;
      setCheckboxError(true);
    }

    if (noErrors) {
      let data = {
        request_id: vacationConfirmation.request_id,
        is_substitute: vacationConfirmation.is_substitute,
      };

      if (vacationConfirmation.request_status !== "awaiting_hr") {
        data["sig"] = sigCanvasRef.current.toDataURL("image/png");
      }

      props.handleConfirmationSubmission(data);
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={vacationConfirmation.open_approve_overlay}
      >
        <form onSubmit={handleSubmitForm}>
          <Card
            sx={{
              maxWidth: "90vw",
              maxHeight: "85vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardHeader
              id="Approve-Request-MuiCardHeader"
              action={
                <IconButton aria-label="close" onClick={handleCloseOverlay}>
                  <CloseIcon />
                </IconButton>
              }
              sx={{ pb: 0, width: "100%" }}
              subheader={
                vacationConfirmation.is_substitute ? "Aprobă ca înlocuitor" : ""
              }
            />

            {vacationConfirmation.request_status !== "awaiting_hr" ? (
              <CardContent>
                <Typography
                  variant="body2"
                  align="left"
                  sx={{ width: "100%", mb: "0.2rem" }}
                >
                  {isSmallScreen
                    ? "Introduceți semnătura"
                    : "Introduceți semnătura în spațiul de jos"}
                </Typography>
                <div className="sig-block">
                  <SignatureCanvas
                    ref={sigCanvasRef}
                    penColor="black"
                    canvasProps={{ className: "sig-canvas" }}
                  />
                  <IconButton
                    id="sig-clear"
                    onClick={handleClearSignature}
                    aria-label="clear-signature"
                    size="small"
                    color="secondary"
                  >
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </CardContent>
            ) : (
              <CardContent>
                <FormControl error={checkboxError}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => {
                            setChecked(event.target.checked);
                            setCheckboxError(false);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      }
                      label="Confirmați aprobarea cererii"
                    />
                  </FormGroup>
                  {!!checkboxError && (
                    <FormHelperText>
                      Pentru aprobare, bifați căsuța de mai sus.
                    </FormHelperText>
                  )}
                </FormControl>
              </CardContent>
            )}

            <CardActions>
              <LoadingButton
                variant="contained"
                size="small"
                color="secondary"
                type="submit"
                loading={loading}
                loadingPosition="center"
              >
                Aprobă Cererea
              </LoadingButton>
            </CardActions>
          </Card>
        </form>
      </Backdrop>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Introduceți semnătura!
        </Alert>
      </Snackbar>
    </>
  );
};

export default ApproveRequest;
