import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createStore, applyMiddleware } from 'redux'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { roRO } from '@mui/material/locale';

import './index.css';
import App from './App';
import stateReducer from './reducers/stateReducer'

const theme = createTheme({
  palette: {
    secondary: {
      main: '#6366f1',
      light: '#c7d2fe',
      dark: '#4338ca'
    }
  },
  roRO
});

const store = createStore(stateReducer, applyMiddleware(thunk))

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);
