import axios from "axios";

export const addNewLeaveRequest = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/leave-requests`,
        data,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 201) {
        dispatch({ type: "STOP_LOADING" });
        window.location.replace("/");
      } else {
        throw Error();
      }
    } catch (err) {
      let error_message =
        "Ceva nu a funcționat correct! Vă rugăm verificați informațiile introduse și încercați din nou!";
      if (err.response.data === "Substitute user not found") {
        error_message =
          "Cererea nu a fost trimisă. Vă rugăm verificați adresa de e-mail a înlocuitorului și încercați din nou!";
      } else if (err.response.data === "Not enough vacation days left") {
        error_message =
          "Cererea nu a fost trimisă. Nu aveți destule zile de concediu rămase.";
      }
      dispatch({
        type: "ERROR",
        payload: error_message,
      });
    }
  };
};

export const getRequestsOfOthers = () => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/leave-requests/of-others`,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        response.data.subordinates = response.data.subordinates.map(
          (employee, index) => {
            return { ...employee, id: index + 1 };
          }
        );
        const leaveRequests = separateToRequestsByTable(response.data);

        dispatch({
          type: "SET_VACATION_REQUESTS_OF_OTHERS",
          payload: {
            subordinates_original: response.data.subordinates,
            table_separated: leaveRequests,
          },
        });
        dispatch({ type: "STOP_LOADING" });
      } else {
        throw Error();
      }
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload:
          "Ceva nu a funcționat correct! Vă rugăm să reîncărcați pagina!",
      });
    }
  };
};

export const approveRequest = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/leave-requests/approve`,
        data,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        dispatch({ type: "SET_VACATION_CONFIRMATION" });
        dispatch({ type: "STOP_LOADING" });
        return "ok";
      } else {
        throw Error();
      }
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload: "Ceva nu a funcționat correct! Cererea nu a fost aprobată",
      });
    }
  };
};

export const denyRequest = (data) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/leave-requests/deny`,
        data,
        {
          headers: {
            authorization: sessionStorage.getItem("token"),
          },
        }
      );
      if (response.status === 200) {
        dispatch({ type: "SET_VACATION_CONFIRMATION" });
        dispatch({ type: "STOP_LOADING" });
        return "ok";
      } else {
        throw Error();
      }
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload: "Ceva nu a funcționat correct! Cererea nu a fost respinsă",
      });
    }
  };
};

export const downloadLeaveRequestPDF = (id) => {
  return async (dispatch) => {
    dispatch({ type: "LOADING" });
    try {
      let windowReference = window.open();

      fetch(`${process.env.REACT_APP_API_URL}/leave-requests/pdf/${id}`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          authorization: sessionStorage.getItem("token"),
        },
      })
        .then((res) => res.blob())
        .then((data) => {
          let fileURL = URL.createObjectURL(data);
          windowReference.location = fileURL;
        });
      dispatch({ type: "STOP_LOADING" });
    } catch (err) {
      dispatch({
        type: "ERROR",
        payload: "Ceva nu a funcționat correct! Vă rugăm să reîncărcați pagina!",
      });
    }
  };
};

//helpers

function separateToRequestsByTable(original) {
  let result = {};
  let noSubRequstIDs = [];
  //separate subordinates:
  if (original.subordinates.length) {
    const tableSeparated = {
      to_approve: [],
      approved: [],
      denied: [],
    };
    original.subordinates.forEach((employee) => {
      if (employee.relationship === "hr") {
        employee.leave_requests.forEach((request) => {
          if (request.status.includes("awaiting_")) {
            tableSeparated["to_approve"].push({
              ...request,
              employee_id: employee.id,
              name: employee.name,
              surname: employee.surname,
              role: employee.role,
              can_approve: request.status === "awaiting_hr" ? true : false,
            });
          } else {
            noSubRequstIDs.push(request.id);
            tableSeparated[request.status].push({
              ...request,
              employee_id: employee.id,
              name: employee.name,
              surname: employee.surname,
              role: employee.role,
            });
          }
        });
      } else if (employee.relationship === "supervisor") {
        employee.leave_requests.forEach((request) => {
          if (request.status.includes("awaiting_su")) {
            noSubRequstIDs.push(request.id);
            tableSeparated["to_approve"].push({
              ...request,
              employee_id: employee.id,
              name: employee.name,
              surname: employee.surname,
              role: employee.role,
              can_approve: request.status === "awaiting_sup" ? true : false,
            });
          } else {
            noSubRequstIDs.push(request.id);
            let table =
              request.status === "awaiting_hr" ? "approved" : request.status;
            tableSeparated[table].push({
              ...request,
              employee_id: employee.id,
              name: employee.name,
              surname: employee.surname,
              role: employee.role,
            });
          }
        });
      }
    });

    result["subordinates"] = tableSeparated;
  } else {
    result["subordinates"] = {
      to_approve: [],
      approved: [],
      denied: [],
    };
  }

  //separate to substitute:
  if (original.to_substitute.length) {
    const tableSeparated = {
      to_approve: [],
      approved: [],
      denied: [],
    };
    original.to_substitute.forEach((employee) => {
      employee.leave_requests.forEach((request) => {
        if (request.status === "awaiting_sub") {
          tableSeparated["to_approve"].push({
            ...request,
            name: employee.name,
            surname: employee.surname,
            role: employee.role,
            can_approve: true,
          });
        } else if (!noSubRequstIDs.includes(request.id)) {
          let table = request.status !== "denied" ? "approved" : request.status;
          tableSeparated[table].push({
            ...request,
            name: employee.name,
            surname: employee.surname,
            role: employee.role,
          });
        }
      });
    });

    result["to_substitute"] = tableSeparated;
  } else {
    result["to_substitute"] = {
      to_approve: [],
      approved: [],
      denied: [],
    };
  }

  return result;
}
