import { Paper, Zoom } from "@mui/material";

function RegisterMessage() {
  return (
    <div className="auth-body">
      <Zoom in={true}>
        <Paper elevation={12} id="auth-card">
          <h2 style={{ color: "#6b7280" }}>Verificare E-mail</h2>
          <p style={{ color: "grey" }}>
            Verificați-vă e-mailul pentru link-ul de activare. Acest link rămâne
            valabil timp de 15 minute. În cazul în care timpul a expirat,
            completați un nou formular de înregistrare.
          </p>

          <a id="auth-btns-3" href="/login">
            Înapoi la Autentificare
          </a>
        </Paper>
      </Zoom>
    </div>
  );
}

export default RegisterMessage;
