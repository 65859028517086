import { Link } from "react-router-dom";
import { Paper } from "@mui/material";

import {
  CircularBar,
  MultiMonthCalendar,
  LeaveHistory,
} from "../../components";
import "./style.css";

function Vacation() {
  return (
    <>
      <div className="vacation-main">
        <div className="vacation-nav" id="item-1">
          <Paper elevation={2} className="vacation-card-top">
            <img
              src={process.env.PUBLIC_URL + `/images/approval_pending.png`}
              alt="logo"
            />
            <Link to="/cererile-tale">
              Cereri trimise
            </Link>
          </Paper>

          <Paper elevation={2} className="vacation-card-top">
            <img
              src={process.env.PUBLIC_URL + `/images/approval_required.png`}
              alt="logo"
            />
            <Link to="/aprobare-cereri">Cereri de aprobat</Link>
          </Paper>

          <Paper elevation={2} className="vacation-card-top">
            <img
              src={process.env.PUBLIC_URL + `/images/new_form.png`}
              alt="logo"
            />
            <Link to="/cerere-concediu">
              Completează o nouă cerere
            </Link>
          </Paper>
        </div>
        <Paper elevation={2} className="vacation-card" id="item-2">
          <CircularBar
            left={sessionStorage.getItem("days_left")}
            total={sessionStorage.getItem("days_total")}
          />
        </Paper>

        <Paper elevation={2} className="vacation-card" id="item-3">
          <LeaveHistory />
        </Paper>

        <Paper elevation={2} className="vacation-card" id="item-4">
          <MultiMonthCalendar />
        </Paper>
      </div>
    </>
  );
}

export default Vacation;
