import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  IconButton,
  Avatar,
  Tooltip,
  Menu,
  Fade,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";

import "./style.css";

function NavBar() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpenAnchor = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    sessionStorage.clear();
    window.location.replace("/login");
  }

  return (
    <nav>
      <img
        src={process.env.PUBLIC_URL + `/images/logo.png`}
        alt="logo"
        onClick={() => navigate("/")}
      />
      <Tooltip title="Meniu utilizator">
        <IconButton
          onClick={handleOpenAnchor}
          size="large"
          aria-label="open drawer"
          aria-controls={open ? "fade-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 45, height: 45 }} />
        </IconButton>
      </Tooltip>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseAnchor}
        TransitionComponent={Fade}
      >
        <MenuItem
          disabled
          onClick={() => {
            handleCloseAnchor();
          }}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Administrare cont"} />
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            handleCloseAnchor();
            handleLogout();
          }}
        >
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"Deconectare"} />
        </MenuItem>
      </Menu>
    </nav>
  );
}

export default NavBar;
