import { useState, useMemo, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  useMediaQuery,
  Autocomplete,
} from "@mui/material";
import { styled, lighten, darken } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/ro";

import { calculateWorkdayDifference } from "../../util/calendar";
import { NewRequestConfirm } from "../../components";
import { roRO } from "../../util/dateLocaleRO";
import { addNewLeaveRequest } from "../../actions/leaveActions";
import "./style.css";

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});

const VacationRequest = () => {
  const dispatch = useDispatch();
  const emailSupervisor = useSelector((state) => state.email_supervisor);

  const sigCanvasRef = useRef(null);
  const [openOverlay, setOpenOverlay] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function dateErrorMessages(error) {
    switch (error) {
      case "maxDate": {
        return "Data invalidă: depășește data maximă";
      }
      case "minDate": {
        return "Data invalidă: depășește data minimă";
      }
      case "invalidDate": {
        return "Data invalidă";
      }
      case "emptyDate": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  function workDaysErrorMessages(error) {
    switch (error) {
      case "empty": {
        return "Completați acest spațiu";
      }
      case "invalid": {
        return "Număr invalid";
      }
      default: {
        return "";
      }
    }
  }

  const [errorStartDate, setErrorStartDate] = useState(null);
  const errorMessageStartDate = useMemo(() => {
    return dateErrorMessages(errorStartDate);
  }, [errorStartDate]);

  const [errorEndDate, setErrorEndDate] = useState(null);
  const errorMessageEndDate = useMemo(() => {
    return dateErrorMessages(errorEndDate);
  }, [errorEndDate]);

  const [workDaysError, setWorkDaysError] = useState(null);
  const [subError, setSubError] = useState(null);
  const [otherDetailsError, setOtherDetailsError] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const handleStartDateChange = (date) => {
    if (errorStartDate === "emptyDate") setErrorStartDate(null);
    setStartDate(date);
    if (endDate !== null) {
      if (
        leaveType === "vacation" ||
        leaveType === "other"
      ) {
        setWorkDays(calculateWorkdayDifference(date, endDate));
      }
      if (date > endDate) {
        setEndDate(date);
      }
    }
  };

  const [endDate, setEndDate] = useState(null);
  const handleEndDateChange = (date) => {
    if (errorEndDate === "emptyDate") setErrorEndDate(null);
    setEndDate(date);
    if (startDate !== null) {
      if (
        leaveType === "vacation" ||
        leaveType === "other"
      ) {
        setWorkDays(calculateWorkdayDifference(startDate, date));
      }
      if (date < startDate) {
        setStartDate(date);
      }
    }
  };

  const [leaveType, setLeaveType] = useState("vacation");
  const handleLeaveTypeChange = (event) => {
    setLeaveType(event.target.value);
  };

  function validateFormItems(e) {
    const maxWorkDays = endDate.diff(startDate, "day") + 1;

    const { email_substitute, work_days, other_details } = e.target;
    const errors = {
      startDate: !startDate || !startDate.isValid() ? "emptyDate" : "",
      endDate: !endDate || !endDate.isValid() ? "emptyDate" : "",
      work_days: !work_days.value
        ? "empty"
        : Number.isInteger(Number(work_days.value)) &&
          work_days.value >= 0 &&
          work_days.value <= maxWorkDays
        ? ""
        : "invalid",
      email_substitute: !email_substitute.value ? true : false,
      other_details:
        leaveType === "other" && !other_details.value ? true : false,
    };

    setErrorStartDate(errors.startDate);
    setErrorEndDate(errors.endDate);
    setWorkDaysError(errors.work_days);
    setSubError(errors.email_substitute);
    setOtherDetailsError(errors.other_details);

    return Object.values(errors).every((error) => !error);
  }

  const [leaveRequestData, setLeaveRequestData] = useState({});
  function handleVacationFrom(e) {
    e.preventDefault();

    const validItems = validateFormItems(e);

    if (validItems) {
      let formData = {
        start_date: startDate.unix(),
        end_date: endDate.add(23, "hour").add(59, "minute").unix(),
        work_days: parseInt(e.target.work_days.value),
        leave_type: leaveType,
        email_substitute: e.target.email_substitute.value,
        other_details: null,
      };

      if (e.target.other_details)
        formData["other_details"] = e.target.other_details.value;

      setLeaveRequestData(formData);
      setOpenOverlay(true);
    }
  }

  async function handleConfirmationSubmission() {
    let finalLeaveRequestData = leaveRequestData;

    finalLeaveRequestData["applicant_sig"] =
      sigCanvasRef.current.toDataURL("image/png");

    dispatch(addNewLeaveRequest(finalLeaveRequestData));
  }

  const [workDays, setWorkDays] = useState("");
  const [emailSubstitute, setEmailSubstitute] = useState(null);
  useEffect(() => {
    if (leaveType === "no_pay") {
      setStartDate(null);
      setEndDate(null);
      setEmailSubstitute({
        email: emailSupervisor,
        label: "E-mail Coordonator Departament",
      });
    }

    if (
      leaveType === "no_pay" ||
      leaveType === "event_fam" ||
      leaveType === "child"
    ) {
      setWorkDays(0);
    }
  }, [leaveType, emailSupervisor]);

  return (
    <Paper elevation={12} sx={{ py: "1.5rem" }}>
      <form onSubmit={handleVacationFrom}>
        <h2>Cerere de Concediu</h2>
        <div className="form-section">
          <div className="form-section-col-l">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={roRO}
              adapterLocale="ro"
            >
              <DatePicker
                required
                label="Data începere concediu (incl.)"
                value={startDate}
                onChange={handleStartDateChange}
                sx={{ width: "100%", my: "0.7rem" }}
                minDate={
                  leaveType === "no_pay"
                    ? dayjs().add(6, "day")
                    : dayjs().add(1, "day")
                }
                maxDate={endDate ? endDate : dayjs().add(1, "year")}
                format="DD/MM/YYYY"
                onError={(newError) => setErrorStartDate(newError)}
                slotProps={{
                  textField: {
                    helperText: errorMessageStartDate,
                    ...(errorStartDate === "emptyDate" ? { error: true } : {}),
                  },
                }}
              />
            </LocalizationProvider>

            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={roRO}
              adapterLocale="ro"
            >
              <DatePicker
                label="Data sfarșit concediu (incl.)"
                disabled={startDate && startDate.isValid() ? false : true}
                value={endDate}
                onChange={handleEndDateChange}
                sx={{ width: "100%", my: "0.7rem" }}
                minDate={startDate ? startDate : dayjs().add(1, "day")}
                maxDate={dayjs().add(1, "year")}
                format="DD/MM/YYYY"
                firstDayOfWeek="monday"
                onError={(newError) => setErrorEndDate(newError)}
                slotProps={{
                  textField: {
                    helperText: errorMessageEndDate,
                    ...(errorEndDate === "emptyDate" ? { error: true } : {}),
                  },
                }}
              />
            </LocalizationProvider>

            <TextField
              inputProps={{
                inputMode: "decimal",
                type: "number",
                step: "any",
                pattern: "d*",
              }}
              id="work_days"
              disabled={
                leaveType === "no_pay" ||
                leaveType === "event_fam" ||
                leaveType === "child"
                  ? true
                  : false
              }
              label="Numărul de zile lucrătoare"
              sx={{ width: "100%", my: "0.7rem" }}
              value={workDays}
              onChange={(event) => {
                setWorkDays(event.target.value);
                setWorkDaysError(null);
              }}
              helperText={workDaysErrorMessages(workDaysError)}
              error={workDaysError ? true : false}
            />

            <Autocomplete
              id="email_substitute"
              freeSolo
              disabled={leaveType === "no_pay" ? true : false}
              options={[
                {
                  email: emailSupervisor,
                  label: "E-mail Coordonator Departament",
                },
              ]}
              groupBy={(option) => option.label}
              getOptionLabel={(option) => option.email}
              sx={{ width: "100%", my: "0.7rem" }}
              value={emailSubstitute}
              onChange={(event, newValue) => {
                setEmailSubstitute(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="E-mail înlocuitor"
                  onChange={() => setSubError(null)}
                  helperText={subError ? "Completați acest spațiu" : ""}
                  error={subError ? true : false}
                />
              )}
              renderGroup={(params) => (
                <li key={params.key}>
                  <GroupHeader>{params.group}</GroupHeader>
                  <GroupItems>{params.children}</GroupItems>
                </li>
              )}
            />
          </div>

          <div className="form-section-col-r">
            <FormControl sx={{ mt: "0.7rem" }}>
              <FormLabel id="vacation-type" sx={{ width: "fit-content" }}>
                Tip concediu
              </FormLabel>
              <RadioGroup
                aria-labelledby="vacation-type"
                name="vacation-type-radio-buttons-group"
                value={leaveType}
                onChange={handleLeaveTypeChange}
              >
                <FormControlLabel
                  value="vacation"
                  control={<Radio color="secondary" />}
                  label={isSmallScreen ? "Odihnă" : "Concediu de odihnă"}
                />
                <FormControlLabel
                  value="event_fam"
                  control={<Radio color="secondary" />}
                  label={
                    isSmallScreen
                      ? "Eveniment familial"
                      : "Concediu pentru evenimente familiale"
                  }
                />
                <FormControlLabel
                  value="child"
                  control={<Radio color="secondary" />}
                  label={
                    isSmallScreen
                      ? "Îngrijire copil"
                      : "Concediu creștere și îngrijire copil"
                  }
                />
                <FormControlLabel
                  value="no_pay"
                  control={<Radio color="secondary" />}
                  label={isSmallScreen ? "Fără plată" : "Concediu fără plată"}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio color="secondary" />}
                  label="Altă formă"
                />
              </RadioGroup>
            </FormControl>

            {leaveType === "other" && (
              <TextField
                variant="standard"
                id="other_details"
                label="Vă rugăm să specificaţi"
                sx={{ width: "100%", mb: "0.7rem" }}
                onChange={() => setOtherDetailsError(null)}
                helperText={otherDetailsError ? "Completați acest spațiu" : ""}
                error={otherDetailsError ? true : false}
              />
            )}
          </div>
        </div>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          sx={{ width: "fit-content" }}
        >
          Semnare Cerere
        </Button>
      </form>

      <NewRequestConfirm
        sigCanvasRef={sigCanvasRef}
        openOverlay={openOverlay}
        setOpenOverlay={setOpenOverlay}
        handleConfirmationSubmission={handleConfirmationSubmission}
      />
    </Paper>
  );
};

export default VacationRequest;
