import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
} from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";
import "moment/locale/ro";

import "./style.css";
moment.locale("ro");

const LinearCalendar = (props) => {
  const personalRequests = useSelector((state) => state.vacation_requests);

  const leaveTypeMapping = {
    vacation: "Odihnă",
    event_fam: "Eveniment familial",
    child: "Îngrijire copil",
    other: "Altă formă",
    no_pay: "Fără plată",
  };

  const [groups, setGroups] = useState([]);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const newGroups = [];
    const newItems = [];

    newGroups.push({
      id: 0,
      title: "Calendarul Tău",
      rightTitle: "-",
    });

    props.employees.forEach((employee) => {
      newGroups.push({
        id: employee.id,
        title: employee.surname + " " + employee.name,
        rightTitle: employee.role,
      });
    });

    personalRequests.forEach((request, index) => {
      if (request.status !== "denied") {
        newItems.push({
          id: "me_" + index,
          group: 0,
          title: leaveTypeMapping[request.leave_type],
          start_time: moment.unix(request.start_date),
          end_time: moment.unix(request.end_date),
          bgColor: request.status === "approved" ? "#84cc16" : "#bae6fd",
          color: "black",
          selectedBgColor:
            request.status === "approved" ? "#a3e635" : "#e0f2fe",
        });
      }
    });

    props.leaveRequests["to_approve"].forEach((request) => {
      newItems.push({
        id: request.id,
        group: request.employee_id,
        title: leaveTypeMapping[request.leave_type],
        start_time: moment.unix(request.start_date),
        end_time: moment.unix(request.end_date),
        bgColor: request.can_approve ? "#99f6e4" : "#bae6fd",
        color: "black",
        selectedBgColor: request.can_approve ? "#ccfbf1" : "#e0f2fe",
      });
    });

    props.leaveRequests["approved"].forEach((request) => {
      newItems.push({
        id: request.id,
        group: request.employee_id,
        title: leaveTypeMapping[request.leave_type],
        start_time: moment.unix(request.start_date),
        end_time: moment.unix(request.end_date),
        bgColor: "#84cc16",
        color: "black",
        selectedBgColor: "#a3e635",
      });
    });

    setGroups(newGroups);
    setItems(newItems);
  }, [props.employees, props.leaveRequests, personalRequests]);

  const itemRender = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
    const backgroundColor = itemContext.selected
      ? item.selectedBgColor
      : item.bgColor;
    return (
      <div
        {...getItemProps({
          style: {
            backgroundColor,
            color: item.color,
            borderRadius: 4,
            boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.2),
                     0 2px 2px 0 rgba(0, 0, 0, 0.14),
                     0 3px 1px -2px rgba(0, 0, 0, 0.12)`,
          },
        })}
      >
        {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

        <div
          className="rct-item-content"
          style={{
            height: itemContext.dimensions.height,
            overflow: "hidden",
            paddingLeft: 3,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {itemContext.title}
        </div>

        {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
      </div>
    );
  };

  return (
    <div className="timeline-container">
      {!!groups.length && (
        <Timeline
          groups={groups}
          items={items}
          lineHeight={40}
          rightSidebarWidth={300}
          sidebarWidth={300}
          defaultTimeStart={moment()}
          defaultTimeEnd={moment().add(1, "month")}
          minZoom={30 * 24 * 60 * 60 * 1000}
          maxZoom={30 * 24 * 60 * 60 * 1000}
          canMove={false}
          canResize={false}
          stackItems
          itemRenderer={itemRender}
        >
          <TimelineHeaders style={{ backgroundColor: "#6366f1" }}>
            <SidebarHeader>
              {({ getRootProps }) => {
                const props = getRootProps();
                props.style = {
                  ...props.style,
                  color: "white",
                  height: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                };
                return <div {...props}>Nume</div>;
              }}
            </SidebarHeader>
            <SidebarHeader variant="right">
              {({ getRootProps }) => {
                const props = getRootProps();
                props.style = {
                  ...props.style,
                  color: "white",
                  height: "inherit",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                };
                return <div {...props}>Funcție</div>;
              }}
            </SidebarHeader>
            <DateHeader unit="primaryHeader" />
            <DateHeader />
          </TimelineHeaders>
        </Timeline>
      )}
    </div>
  );
};

export default LinearCalendar;
