import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IconButton,
  Button,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";

import "./style.css";

const RejectRequest = (props) => {
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.loading);
  const vacationConfirmation = useSelector(
    (state) => state.vacation_confirmation
  );

  function handleCloseOverlay() {
    dispatch({
      type: "SET_VACATION_CONFIRMATION",
    });
  }

  const [denialReasonError, setDenialReasonError] = useState(null);
  function handleSubmitForm(e) {
    e.preventDefault();

    if (!e.target.denial_reason.value) {
      setDenialReasonError(true);
    } else {
      let data = {
        request_id: vacationConfirmation.request_id,
        denial_reason: e.target.denial_reason.value,
      };

      props.handleConfirmationSubmission(data);
    }
  }

  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={vacationConfirmation.open_deny_overlay}
    >
      <form onSubmit={handleSubmitForm}>
        <Card
          sx={{
            maxWidth: "90vw",
            maxHeight: "85vh",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardHeader
            action={
              <IconButton aria-label="close" onClick={handleCloseOverlay}>
                <CloseIcon />
              </IconButton>
            }
            sx={{ pb: 0, width: "100%" }}
          />

          <CardContent>
            <TextField
              id="denial_reason"
              className="overlay-content"
              placeholder="Motivul respingerii"
              multiline
              rows={4}
              onChange={() => setDenialReasonError(null)}
              helperText={denialReasonError ? "Completați acest spatiu" : ""}
              error={denialReasonError ? true : false}
              inputProps={{ maxLength: 250 }}
            />
          </CardContent>

          <CardActions>
            <LoadingButton
              variant="contained"
              size="small"
              color="secondary"
              type="submit"
              loading={loading}
              loadingPosition="center"
            >
              Respinge Cererea
            </LoadingButton>
          </CardActions>
        </Card>
      </form>
    </Backdrop>
  );
};

export default RejectRequest;
