import { forwardRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import {
  IconButton,
  Snackbar,
  Button,
  Backdrop,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";

import "./style.css";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewRequestConfirm = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClearSignature = () => {
    props.sigCanvasRef.current.clear();
  };

  function handleSubmitForm(e) {
    e.preventDefault();

    if (props.sigCanvasRef.current.isEmpty()) {
      handleOpenSnackbar();
    } else {
      props.handleConfirmationSubmission();
    }
  }

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={props.openOverlay}
      >
        <form onSubmit={handleSubmitForm}>
          <Card
            sx={{
              maxWidth: "90vw",
              maxHeight: "85vh",
              overflow: "auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CardHeader
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => props.setOpenOverlay(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
              sx={{ pb: 0, width: "100%" }}
            />

            <CardContent>
              <Typography
                variant="body2"
                align="left"
                sx={{ width: "100%", mb: "0.2rem" }}
              >
                {isSmallScreen
                  ? "Introduceți semnătura"
                  : "Introduceți semnătura în spațiul de jos"}
              </Typography>
              <div className="sig-block">
                <SignatureCanvas
                  ref={props.sigCanvasRef}
                  penColor="black"
                  canvasProps={{ className: "sig-canvas" }}
                />
                <IconButton
                  id="sig-clear"
                  onClick={handleClearSignature}
                  aria-label="clear-signature"
                  size="small"
                  color="secondary"
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>
              </div>
            </CardContent>

            <CardActions>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                type="submit"
              >
                Trimite Cererea
              </Button>
            </CardActions>
          </Card>
        </form>
      </Backdrop>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          Introduceți semnătura!
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewRequestConfirm;
