import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { IconButton } from '@mui/material';
import dayjs from "dayjs"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { generateDate, months } from "../../util/calendar";
import './style.css'

const MultiMonthCalendar = () => {
    const requests = useSelector((state) => state.vacation_requests);
    const days = ["L", "M", "M", "J", "V", "S", "D"]
    const [mainMonth, setMainMonth] = useState(dayjs())
    const [previousMonth, setPreviousMonth] = useState(dayjs().month(dayjs().month() - 1))
    const [nextMonth, setNextMonth] = useState(dayjs().month(dayjs().month() + 1))

    const [vacationDaysMain, setVacationDaysMain] = useState([])
    const [vacationDaysPrevious, setVacationDaysPrevious] = useState([])
    const [vacationDaysNext, setVacationDaysNext] = useState([])

    useEffect(() => {
        setVacationDaysMain([])
        setVacationDaysPrevious([])
        setVacationDaysNext([])
        const previousMonthStartTime = previousMonth.startOf("month").unix()
        const previousMonthEndTime = previousMonth.endOf("month").unix()

        const mainMonthStartTime = mainMonth.startOf("month").unix()
        const mainMonthEndTime = mainMonth.endOf("month").unix()

        const nextMonthStartTime = nextMonth.startOf("month").unix()
        const nextMonthEndTime = nextMonth.endOf("month").unix()
        const oneDay = 24 * 60 * 60

        for (const request of requests) {
            if (request.start_date <= nextMonthEndTime && request.end_date >= previousMonthStartTime && request.status !== "denied") {
                for (let day = request.start_date; day <= request.end_date; day += oneDay) {
                    if (day >= previousMonthStartTime && day <= previousMonthEndTime) {
                        if (dayjs.unix(day).day() !== 6 && dayjs.unix(day).day() !== 0) {
                            setVacationDaysPrevious(prevList => [...prevList, dayjs.unix(day).date()])
                        }
                    } else if (day >= mainMonthStartTime && day <= mainMonthEndTime) {
                        if (dayjs.unix(day).day() !== 6 && dayjs.unix(day).day() !== 0) {
                            setVacationDaysMain(prevList => [...prevList, dayjs.unix(day).date()])
                        }
                    } else if (day >= nextMonthStartTime && day <= nextMonthEndTime) {
                        if (dayjs.unix(day).day() !== 6 && dayjs.unix(day).day() !== 0) {
                            setVacationDaysNext(prevList => [...prevList, dayjs.unix(day).date()])
                        }
                    }
                }
            }
        }
    }, [mainMonth, requests]) // eslint-disable-line react-hooks/exhaustive-deps

    function handlePreviousMonth() {
        setMainMonth(mainMonth.month(mainMonth.month() - 1))
        setPreviousMonth(previousMonth.month(previousMonth.month() - 1))
        setNextMonth(nextMonth.month(nextMonth.month() - 1))
    }

    function handleNextMonth() {
        setMainMonth(mainMonth.month(mainMonth.month() + 1))
        setPreviousMonth(previousMonth.month(previousMonth.month() + 1))
        setNextMonth(nextMonth.month(nextMonth.month() + 1))
    }

    return (
        <div style={{ display: 'flex' }}>
            <div className="previous-calendar">

                <div style={{ display: 'grid', width: '100%', justifyItems: 'center', gridTemplateColumns: '15% 70% 15%' }}>
                    <IconButton onClick={handlePreviousMonth} aria-label="previous-month" size="small" color="secondary" sx={{ my: '0.9rem' }}>
                        <ArrowBackIosIcon fontSize="inherit" />
                    </IconButton>

                    <h5>{months[previousMonth.month()]} {previousMonth.year()}</h5>
                </div>

                <div className="calendar-days">
                    {days.map((day, index) => {
                        return <h5 key={index}>{day}</h5>
                    })}
                </div>

                <div className="calendar">
                    {generateDate(previousMonth.month(), previousMonth.year()).map(({ date, today }, index) => {
                        return (
                            <div
                                key={index}
                                className={date ? `${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-bg' : ''}` : ''}
                            >
                                {date
                                    ?
                                    <h5
                                        className={`
                                        ${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-txt' : ''} 
                                        ${today ? 'calendar-today' : ''}
                                        ${vacationDaysPrevious.includes(date.date()) ? 'calendar-vacation' : ''}
                                        `}
                                    >
                                        {date.date()}
                                    </h5>
                                    :
                                    <></>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className="main-calendar">

                <div style={{ display: 'grid', width: '100%', justifyItems: 'center', gridTemplateColumns: '15% 70% 15%' }}>
                    <IconButton className="calendar-back-btn-mobile" onClick={handlePreviousMonth} aria-label="previous-month" size="small" color="secondary" sx={{ my: '0.9rem' }}>
                        <ArrowBackIosIcon fontSize="inherit" />
                    </IconButton>

                    <h5 style={{ gridColumn: '2' }}>{months[mainMonth.month()]} {mainMonth.year()}</h5>

                    <IconButton className="calendar-next-btn-mobile" onClick={handleNextMonth} aria-label="next-month" size="small" color="secondary" sx={{ my: '0.9rem' }}>
                        <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <div className="calendar-days">
                    {days.map((day, index) => {
                        return <h5 key={index}>{day}</h5>
                    })}
                </div>

                <div className="calendar">
                    {generateDate(mainMonth.month(), mainMonth.year()).map(({ date, today }, index) => {
                        return (
                            <div
                                key={index}
                                className={date ? `${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-bg' : ''}` : ''}
                            >
                                {date
                                    ?
                                    <h5
                                        className={`
                                        ${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-txt' : ''} 
                                        ${today ? 'calendar-today' : ''}
                                        ${vacationDaysMain.includes(date.date()) ? 'calendar-vacation' : ''}
                                    `}
                                    >
                                        {date.date()}
                                    </h5>
                                    :
                                    <></>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>

            <div className="next-calendar">

                <div style={{ display: 'grid', width: '100%', justifyItems: 'center', gridTemplateColumns: '10% 80% 10%' }}>

                    <h5 style={{ gridColumn: '2' }}>{months[nextMonth.month()]} {nextMonth.year()}</h5>

                    <IconButton onClick={handleNextMonth} aria-label="next-month" size="small" color="secondary" sx={{ my: '0.9rem' }}>
                        <ArrowForwardIosIcon fontSize="inherit" />
                    </IconButton>
                </div>

                <div className="calendar-days">
                    {days.map((day, index) => {
                        return <h5 key={index}>{day}</h5>
                    })}
                </div>

                <div className="calendar">
                    {generateDate(nextMonth.month(), nextMonth.year()).map(({ date, today }, index) => {
                        return (
                            <div
                                key={index}
                                className={date ? `${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-bg' : ''}` : ''}
                            >
                                {date
                                    ?
                                    <h5
                                        className={`
                                        ${date.day() === 6 || date.day() === 0 ? 'calendar-weekend-txt' : ''} 
                                        ${today ? 'calendar-today' : ''}
                                        ${vacationDaysNext.includes(date.date()) ? 'calendar-vacation' : ''}
                                    `}
                                    >
                                        {date.date()}
                                    </h5>
                                    :
                                    <></>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>
    );
};


export default MultiMonthCalendar;