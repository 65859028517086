import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function ErrorAlerts() {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "ERROR", payload: "" });
    setOpenSnackbar(false);
  };
  //open alert if error has appeared
  useEffect(() => {
    if (error) {
      handleOpenSnackbar();
    }
  }, [error]);

  return (
    <Snackbar
      className="auth-alert"
      open={openSnackbar}
      onClose={handleCloseSnackbar}
    >
      <Alert
        onClose={handleCloseSnackbar}
        severity="error"
        sx={{ width: "100%" }}
      >
        {error}
      </Alert>
    </Snackbar>
  );
}

export default ErrorAlerts;
