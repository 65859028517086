import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  TextField,
  Zoom,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { LoadingButton } from "@mui/lab";

import { StrengthMeter } from "../../../components";
import { registerUser } from "../../../actions/userActions";

function RegisterForm() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const [CNP, setCNP] = useState("");
  function handleChangeCNP(e) {
    if (
      parseInt(e.target.value) < 9999999999999 &&
      parseInt(e.target.value) > 0
    ) {
      if (parseInt(e.target.value)) {
        setCNP(parseInt(e.target.value));
      }
    } else if (!e.target.value) {
      setCNP("");
    }
  }

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [passwordProgress, setPasswordProgress] = useState(0);

  function cnpErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "CNP invalid";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  function emailErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "Adresă de email invalidă. Domenii permise: @antenagroup.ro, @intactmediagroup.ro, @antena3.ro";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  function passErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  function confirmPassErrorMessages(error) {
    switch (error) {
      case "invalid": {
        return "Parola nu corespunde";
      }
      case "empty": {
        return "Completați acest spațiu";
      }
      default: {
        return "";
      }
    }
  }

  const [cnpError, setCnpError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passError, setPassError] = useState(null);
  const [confirmPassError, setConfirmPassError] = useState(null);

  function validateFormItems(e) {
    const {
      register_cnp,
      register_email,
      register_password,
      register_password_conf,
    } = e.target;

    const emaiDomain = register_email.value.split('@')[1].toLowerCase();
    const isValidDomain =
      emaiDomain === "antenagroup.ro" ||
      emaiDomain === "intactmediagroup.ro" ||
      emaiDomain === "antena3.ro";

    const errors = {
      cnp: !register_cnp.value
        ? "empty"
        : register_cnp.value < 1000000000000
        ? "invalid"
        : null,
      email: !register_email.value
        ? "empty"
        : !isValidDomain
        ? "invalid"
        : null,
      password: !register_password.value
        ? "empty"
        : passwordProgress !== 100
        ? "invalid"
        : null,
      password_conf: !register_password_conf.value
        ? "empty"
        : register_password_conf.value !== register_password.value
        ? "invalid"
        : null,
    };

    setCnpError(errors.cnp);
    setEmailError(errors.email);
    setPassError(errors.password);
    setConfirmPassError(errors.password_conf);

    return Object.values(errors).every((error) => !error);
  }

  async function handleRegistration(e) {
    e.preventDefault();

    const validItems = validateFormItems(e);

    if (validItems) {
      const formData = {
        cnp: e.target.register_cnp.value,
        email: e.target.register_email.value,
        password: e.target.register_password.value,
      };

      dispatch(registerUser(formData));
    }
  }

  return (
    <div className="auth-body">
      <Zoom in={true}>
        <Paper elevation={12} id="auth-card">
          <form onSubmit={handleRegistration}>
            <img
              className="auth-logo"
              src={process.env.PUBLIC_URL + `/images/favicon.png`}
              alt="logo"
            />
            <h2 style={{ color: "#6b7280" }}>Înregistrare AntenaPeople</h2>
            <TextField
              value={CNP}
              onChange={(e) => {
                handleChangeCNP(e);
                setCnpError(null);
              }}
              color="secondary"
              id="register_cnp"
              label="COD NUMERIC PERSONAL (CNP)"
              sx={{ width: "100%", my: "0.7rem" }}
              helperText={cnpErrorMessages(cnpError)}
              error={cnpError ? true : false}
            />

            <TextField
              color="secondary"
              id="register_email"
              label="ADRESĂ DE EMAIL"
              autoComplete="email"
              sx={{ width: "100%", my: "0.7rem" }}
              onChange={() => setEmailError(null)}
              helperText={emailErrorMessages(emailError)}
              error={emailError ? true : false}
            />

            <FormControl
              error={passError ? true : false}
              variant="outlined"
              sx={{ width: "100%", my: "0.7rem" }}
            >
              <InputLabel color="secondary" htmlFor="register_password">
                PAROLĂ
              </InputLabel>
              <OutlinedInput
                color="secondary"
                id="register_password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPassError(null);
                }}
                label="PAROLĂ"
                autoComplete="new-password"
              />
              <FormHelperText error id="accountId-error">
                {passErrorMessages(passError)}
              </FormHelperText>
            </FormControl>
            {!!password && (
              <StrengthMeter
                password={password}
                progress={passwordProgress}
                setProgress={setPasswordProgress}
              />
            )}

            <FormControl
              error={confirmPassError ? true : false}
              variant="outlined"
              sx={{ width: "100%", my: "0.7rem" }}
            >
              <InputLabel color="secondary" htmlFor="register_password_conf">
                CONFIRMĂ PAROLA
              </InputLabel>
              <OutlinedInput
                color="secondary"
                id="register_password_conf"
                type={showPasswordConf ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPasswordConf((show) => !show)}
                      onMouseDown={(e) => e.preventDefault()}
                      edge="end"
                    >
                      {showPasswordConf ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                onChange={() => setConfirmPassError(null)}
                label="CONFIRMĂ PAROLA"
                autoComplete="new-password"
              />
              <FormHelperText error id="accountId-error">
                {confirmPassErrorMessages(confirmPassError)}
              </FormHelperText>
            </FormControl>

            <LoadingButton
              id="auth-btn-mobile"
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: "fit-content", mt: "0.7rem" }}
              loading={loading}
              loadingPosition="center"
            >
              Înregistrare
            </LoadingButton>

            <hr width="100%" size="2" align="center" />

            <div id="auth-btns">
              <div></div>

              <LoadingButton
                id="auth-btns-2"
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: "fit-content" }}
                loading={loading}
                loadingPosition="center"
              >
                Înregistrare
              </LoadingButton>

              <a id="auth-btns-3" href="/login">
                Autentificare
              </a>
            </div>
          </form>
        </Paper>
      </Zoom>
    </div>
  );
}

export default RegisterForm;
