import { Outlet, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

import { NavBar } from "../layout";
import { checkToken } from "../actions/userActions";

const PrivateRoutes = () => {
  const dispatch = useDispatch();
  const [tokenValid, setTokenValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const validateToken = async () => {
      const isValid = await dispatch(checkToken());

      setTokenValid(isValid);
      setLoading(false);
    };

    // setTimeout(() => validateToken(), 4000);
    validateToken()

    const interval = setInterval(() => {
      validateToken();
    }, 600000); // 10 minute intervals

    return () => clearInterval(interval);
  }, []);

  if (loading) {
    return <div id="loading-token-verification"> <ClipLoader loading={loading} size={70} color="#6366f1" /> </div>;
  } else {
    return tokenValid ? <><NavBar /><main><Outlet /></main></> : <Navigate to="/login" />;
  }
};

export default PrivateRoutes;
