import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, TextField, Zoom } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import "../style.css";
import { loginUser } from "../../../actions/userActions";

function Login() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.loading);

  const [emailError, setEmailError] = useState(null);
  const [passError, setPassError] = useState(null);

  function validateFormItems(e) {
    const { login_email, login_password } = e.target;

    const errors = {
      email: !login_email.value ? "empty" : null,
      password: !login_password.value ? "empty" : null,
    };

    setEmailError(errors.email);
    setPassError(errors.password);

    return Object.values(errors).every((error) => !error);
  }

  async function handleLogin(e) {
    e.preventDefault();

    const validItems = validateFormItems(e);

    if (validItems) {
      const formData = {
        email: e.target.login_email.value,
        password: e.target.login_password.value,
      };

      dispatch(loginUser(formData));
    }
  }

  return (
    <div className="auth-body">
      <Zoom in={true}>
        <Paper elevation={12} id="auth-card">
          <form onSubmit={handleLogin}>
            <img
              className="auth-logo"
              src={process.env.PUBLIC_URL + `/images/favicon.png`}
              alt="logo"
            />
            <h2 style={{ color: "#6b7280" }}>Login AntenaPeople</h2>
            <TextField
              color="secondary"
              id="login_email"
              label="ADRESĂ DE EMAIL"
              autoComplete="email"
              sx={{ width: "100%", my: "0.7rem" }}
              onChange={() => setEmailError(null)}
              helperText={emailError ? "Completați acest spațiu" : ""}
              error={emailError ? true : false}
            />
            <TextField
              color="secondary"
              id="login_password"
              label="PAROLĂ"
              type="password"
              autoComplete="current-password"
              sx={{ width: "100%", my: "0.7rem" }}
              onChange={() => setPassError(null)}
              helperText={passError ? "Completați acest spațiu" : ""}
              error={passError ? true : false}
            />

            <LoadingButton
              id="auth-btn-mobile"
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ width: "fit-content", mt: "0.7rem" }}
              loading={loading}
              loadingPosition="center"
            >
              Login
            </LoadingButton>

            <hr width="100%" size="2" align="center" />

            <div id="auth-btns">
              <a id="auth-btns-1" href="/forgot-pass">
                Ai uitat parola?
              </a>

              <LoadingButton
                id="auth-btns-2"
                type="submit"
                variant="contained"
                color="secondary"
                sx={{ width: "fit-content" }}
                loading={loading}
                loadingPosition="center"
              >
                Login
              </LoadingButton>

              <a id="auth-btns-3" href="/register">
                Înregistrare
              </a>
            </div>
          </form>
        </Paper>
      </Zoom>
    </div>
  );
}

export default Login;
